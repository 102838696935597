
<template>
<!-- This page is same with views/SellerTourismPercentage.vue file -->
  <main class="page_bg_grey tourism-profit-percent blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
    <p class="main-title weight-700">
      {{ $t('ASIDE.TOURISM_PROFIT_PERCENTAGES') }}
    </p>
    <div class="content-margin">
      <DRFilter @changeBusiness="changeBusiness" ref="drFilter_comp"></DRFilter>
      <p class="sub-title">{{ $t("TOURISM_PROFIT_PERCENTAGES.PERCENTAGE_CASH_PROFIT") }}</p>
      <div class="d-flex flex-wrap align-items-end">
        <div class="px10 small-input">
          <label class="label fs14">{{ $t("TOURISM_PROFIT_PERCENTAGES.DIRECT_SALES_COMMISSION") }}</label>
          <input class="input color-input bg-green1" type="text" v-model="selectedResellerSetting.direct_sale_fee" @keypress="isNumber($event)">
        </div>
        <div class="px10 small-input">
          <label class="label fs14">{{ $t("TOURISM_PROFIT_PERCENTAGES.REFERRAL_SALES_COMMISSION") }}</label>
          <input class="input color-input bg-green1" type="text" v-model="selectedResellerSetting.affiliate_sale_fee" @keypress="isNumber($event)">
        </div>
      </div>
      <p class="sub-title">{{ $t("TOURISM_PROFIT_PERCENTAGES.CREDIT_PROFIT_PERCENTAGE") }}</p>
      <div class="d-flex flex-wrap align-items-end">
        <div class="px10 small-input">
          <label class="label fs14">{{ $t("TOURISM_PROFIT_PERCENTAGES.DIRECT_SALES_COMMISSION") }}</label>
          <input class="input color-input bg-blue" type="text" v-model="selectedResellerSetting.credit_sale_fee" @keypress="isNumber($event)">
        </div>
        <div class="px10 small-input">
          <label class="label fs14">{{ $t("TOURISM_PROFIT_PERCENTAGES.SALES_COMMISSION_DIRECT_LINK") }}</label>
          <input class="input color-input bg-blue" type="text" v-model="selectedResellerSetting.link_sale_fee" @keypress="isNumber($event)">
        </div>
        <div class="px10 small-input">
          <label class="label fs14">{{ $t("TOURISM_PROFIT_PERCENTAGES.INDIRECT_LINK_SALES_COMMISSION") }}</label>
          <input class="input color-input bg-blue" type="text" v-model="selectedResellerSetting.second_link_sale_fee" @keypress="isNumber($event)">
        </div>
      </div>
      <p class="sub-title">{{ $t("TOURISM_PROFIT_PERCENTAGES.FRONTAL_REPRESENTATIVE_FEE") }} ({{ $t("TOURISM_PROFIT_PERCENTAGES.FRONTAL_TXT") }})</p>
      <div class="d-flex flex-wrap align-items-end">
        <div class="px10 small-input">
          <label class="label fs14">{{ $t("TOURISM_PROFIT_PERCENTAGES.DIRECT_SALES_COMMISSION") }}</label>
          <input class="input color-input bg-yellow" type="text" v-model="selectedResellerSetting.front_sale_fee" @keypress="isNumber($event)">
        </div>
        <div class="px10 small-input">
          <label class="label fs14">{{ $t("TOURISM_PROFIT_PERCENTAGES.REFERRAL_SALES_COMMISSION") }}</label>
          <input class="input color-input bg-yellow" type="text" v-model="selectedResellerSetting.direct_front_sale_fee" @keypress="isNumber($event)">
        </div>
        <div class="px10 small-input">
            <label class="label fs14">{{ $t("MANAGEMENT.MIN_FRONTAL_REPRESENTATIVE_FEE") }}</label>
            <input class="input text-center color-white bg-red2" type="text" value="no-api">
          </div>
      </div>
    </div>
    <div class="content-margin m-b-40 d-flex justify-content-center">
      <button class="primary-btn m-t-40"  @click="updateResellerSetting()">
        {{ $t("COMMON.SAVE") }}
      </button>
    </div>
    <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
  </main>
</template>

<script>
import { mapState, mapActions } from "vuex";
import DRFilter from "../../../components/management/DRFilter.vue";
export default {
  name: "DRTourismPercentage",
  components: {
    DRFilter 
  },
  computed: {
    ...mapState({
      filterBusinessData: state => state.seller_tourism_percentage.filterBusinessData,
      resellerSettingData: state => state.seller_tourism_percentage.resellerSettingData,
      loading: state => state.seller_tourism_percentage.loading
    })
  },
  data() {
    return {
      businessID: "",
      distributeID: "",
      OpenIndicator: {
        render: createElement => createElement('span', ''),
      },
      selectedResellerSetting: {},
      defaultSetting: {
        direct_sale_fee: '',
        affiliate_sale_fee: '',
        credit_sale_fee: '',
        link_sale_fee: '',
        second_link_sale_fee: '',
        front_sale_fee: '',
        direct_front_sale_fee: '',
      }
    };
  },
  async created() {
    this.fillInSelectedResellerSetting();
  },
  methods: {
    ...mapActions("seller_tourism_percentage", {
      getResellerSetting: "getResellerSetting",
      update_resellerSetting: "update_resellerSetting",
      filterSellerPercentage: "filterSellerPercentage"
    }),
    async changeBusiness(data) {
      this.businessID = data.business_id;
      this.distributeID = data.distribute_id;
      this.resetResellerSettingData();
      await this.getResellerSetting({id: this.businessID});
      this.fillInSelectedResellerSetting();
    },
    fillInSelectedResellerSetting() {
      this.selectedResellerSetting = {};
      for (let setting_name in this.defaultSetting) {
        this.selectedResellerSetting[setting_name] = setting_name in this.resellerSettingData
          ? this.resellerSettingData[setting_name] : this.defaultSetting[setting_name];
      }
      this.selectedResellerSetting = {...this.selectedResellerSetting};
    },
    resetResellerSettingData() {
      this.selectedResellerSetting = {};
      for (let setting_name in this.defaultSetting) {
        this.selectedResellerSetting[setting_name] = this.defaultSetting[setting_name];
      }
      this.selectedResellerSetting = {...this.selectedResellerSetting};
    },
    updateResellerSetting() {
      const newResellerSetting = {...this.selectedResellerSetting};
      this.update_resellerSetting({
        id: this.businessID,
        params: newResellerSetting
      });
    },
  }
};
</script>

<style lang="scss" scoped>
  .tourism-profit-percent {
    .input {
      margin-bottom: 10px;
    }
    .small-input {
      max-width: 210px;
    }
    .sub-title {
      font-size: 20px;
      margin: 50px 15px 20px;
    }
    .color-input {
      text-align: center;
      color: #ffffff;
    }
  }
</style>